//
// Subheader
//

.subheader {
  display: flex;
  align-items: center;

  // Subheader Separator
  .subheader-separator {
    display: block;
    width: 12px;
    height: 4px;
    border-radius: 2px;

    &.subheader-separator-ver {
      width: 1px;
      height: 22px;
    }
  }

  // Subheader Solid Mode
  &.subheader-solid {
    border-top: 1px solid get($subheader-config, default, border-color);
    background-color: get($subheader-config, default, bg-color);
  }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  .subheader {
    // Fixed subheader mode
    .header-fixed.subheader-fixed & {
      position: fixed;
      height: get($subheader-config, fixed, height);
      top: get($header-config, desktop, fixed, height);
      left: 0;
      right: 0;
      transition: top 0.3s ease;
      z-index: 98;
      box-shadow: get($subheader-config, fixed, shadow);
      background-color: get($subheader-config, default, bg-color);
      border-top: 1px solid get($subheader-config, default, border-color);
      margin: 0;
    }

    // Fixed header, minimized header and fixed subheader
    .header-fixed.header-minimize.subheader-fixed & {
      top: get($header-config, desktop, fixed, height);
      transition: top 0.3s ease;
    }

    // Aside Enabled & Subheader Fixed Modes
    .aside-enabled.subheader-fixed & {
      left: get($aside-config, base, width);
    }

    // Fixed Subheader & Aside Minimize Hover Modes
    .subheader-fixed.aside-minimize-hover &,
    .subheader-fixed.aside-minimize & {
      left: get($aside-config, base, minimized-width);
    }
  }

  // Static Subheader
  .subheader-enabled:not(.subheader-fixed) {
    .content {
      padding-top: 0;
    }

    // Subheder Solid Mode
    .subheader.subheader-solid {
      margin-bottom: get($page-padding, desktop);
    }
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  // Subheder Enabled Mode
  .subheader-enabled {
    // Content
    .content {
      padding-top: 0;
    }

    // Subheder Solid Mode
    .subheader.subheader-solid {
      margin-bottom: get($page-padding, tablet-and-mobile);
    }
  }
}
