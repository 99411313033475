/* You can add global styles to this file, and also import other style files */
// Global vendors
@import '../node_modules/@angular/material/theming';
@import "~bootstrap/dist/css/bootstrap.css";

@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "~ngx-toastr/toastr";

// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-pro/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles
@import "./assets/sass/style.angular.scss";
@import "./assets/sass/vendors/angular/material/angular-material";
@import "./assets/sass/vendors/angular/material/include";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

//hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.mat-table-wrapper-dashboard .mat-table {
  min-width: 600px !important;
  width: 100% !important;
}

.position-static {
  position: static;
}

.dropdown-fluid {
  height: 100%;
  .topbar-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

button,
a {
  &.mat-button,
  &.mat-raised-button,
  &.mat-flat-button,
  &.mat-stroked-button {
    .mat-icon {
      vertical-align: top;
      font-size: 1.25em;
    }
  }
}

.mat-form-field-label {
  font-weight: bold !important;
}

.mat-form-field-label:not(.mat-empty),
.mat-focused .mat-form-field-label {
  font-size: 15px;
}

.mat-hint,
.mat-error {
  font-size: 11px;
}

.mat-focused .mat-form-field-label {
  color: $plusfrotalaranja !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: $plusfrotalaranja !important;
}

.text-plusfrota-claro {
  color: #58585a;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #58585a;
}

.image-input .altImg {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
}

.image-input .remImg {
  position: absolute;
  right: -10px;
  bottom: -5px;
}

.btn-plusfrota {
  color: white;
  background-color: $plusfrotalaranja;
  border-color: $plusfrotalaranja;
}

.btn-plusfrota:hover {
  color: white;
  background-color: $plusfrotalaranjaescuro;
  border-color: $plusfrotalaranjaescuro;
}

.btn-plusfrota i {
  color: white;
}

.btn-plusfrota:hover i {
  color: $plusfrotalaranja;
}

/*.mat-row:nth-child(even) {
  background-color: #f5f5f5;
}

.mat-row:nth-child(odd) {
  background-color: white;
}*/

.mat-header-row {
  background-color: #2d4753 !important;
}

.mat-header-cell {
  color: white;
}
/*
.mat-sort-header-arrow {
  color: white;
}
*/

.svg-icon.svg-icon-plusfrota svg g [fill] {
  fill: $plusfrotalaranja !important;
}

.navi-item .dropdown-item.active,
.dropdown-item:active {
  background-color: #f3f6f9;
}

.bg-cinza-plusfrota {
  background-color: $plusfrotacinzaescuro !important;
}

.bg-bemvindo {
  background-image: url(/assets/media/bg/bemvindo2.jpg);
  background-size: cover;
  background-position: 50%;
  background-origin: content-box;
  width: 100%;
  height: 100%;
  min-height: 600px;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #f26522;
}

.centerspinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  mat-spinner {
    padding: 12px;
  }
}

.backgroundBlackOverlay {
  background: rgba(0, 0, 0, 0.7);
}

.bg-card-transparente {
  background: rgba(255, 255, 255, 0.95) !important;
}

.text-plusfrota {
  color: $plusfrotalaranja;
}

.text-plusfrota:hover {
  color: $plusfrotalaranjaescuro;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: #f26522 !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background-color: #f26522;
}

.mat-checkbox-frame {
  border-color: #58585a;
}

.checkbox.checkbox-outline.checkbox-white > input:checked ~ span {
  background-color: #f26522;
  border-color: #f26522;
}

.mat-mini-fab.mat-danger,
.mat-fab.mat-danger,
.mat-flat-button.mat-danger,
.mat-stroked-button.mat-danger,
.mat-raised-button.mat-danger {
  color: #fff !important;
  background-color: #f44336 !important;
}

.mat-mini-fab.mat-warn,
.mat-fab.mat-warn,
.mat-flat-button.mat-warn,
.mat-stroked-button.mat-warn,
.mat-raised-button.mat-warn {
  color: #fff !important;
  background-color: #ff9800 !important;
}

.mat-mini-fab.mat-danger[disabled],
.mat-fab.mat-danger[disabled],
.mat-flat-button.mat-danger[disabled],
.mat-stroked-button.mat-danger[disabled],
.mat-raised-button.mat-danger[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-fab.mat-warn[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-stroked-button.mat-warn[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-mini-fab.mat-plusfrota[disabled],
.mat-fab.mat-plusfrota[disabled],
.mat-flat-button.mat-plusfrota[disabled],
.mat-stroked-button.mat-plusfrota[disabled],
.mat-raised-button.mat-plusfrota[disabled],
.mat-mini-fab.mat-black[disabled],
.mat-fab.mat-black[disabled],
.mat-flat-button.mat-black[disabled],
.mat-stroked-button.mat-black[disabled],
.mat-raised-button.mat-black[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-mini-fab.mat-plusfrota,
.mat-fab.mat-plusfrota,
.mat-flat-button.mat-plusfrota,
.mat-stroked-button.mat-plusfrota,
.mat-raised-button.mat-plusfrota {
  color: #fff !important;
  background-color: $plusfrotalaranja !important;
}

.mat-button.mat-danger,
.mat-icon-button.mat-danger,
.mat-icon.mat-danger {
  color: #f44336 !important;
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn {
  color: #ff9800 !important;
}

.mat-button.mat-plusfrota,
.mat-icon-button.mat-plusfrota {
  color: $plusfrotalaranja !important;
}

.mat-mini-fab.mat-black,
.mat-fab.mat-black,
.mat-flat-button.mat-black,
.mat-stroked-button.mat-black,
.mat-raised-button.mat-black {
  color: #fff !important;
  background-color: #2b343a !important;
}

.mat-mini-fab.mat-grey,
.mat-fab.mat-grey,
.mat-flat-button.mat-grey,
.mat-stroked-button.mat-grey,
.mat-raised-button.mat-grey {
  background-color: #ddd !important;
}

.mat-raised-button.mat-dark-grey {
  color: #fff !important;
  background-color: #6c7a89 !important;
}

.mat-plusfrota .mat-option.mat-selected:not(.mat-option-disabled),
.mat-form-field.mat-focused.mat-plusfrota .mat-select-arrow {
  color: $plusfrotalaranja;
}

.mat-calendar-body-selected {
  background-color: $plusfrotalaranja;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-datepicker-toggle-active {
  color: $plusfrotalaranja;
}

table.mat-table {
  display: table;
  width: 100%;
}

.mat-header-cell.align-left .mat-sort-header-container,
.mat-cell.align-left {
  padding-left: 10px !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.minwid-0 {
  min-width: 0 !important;
}

.mat-expansion-panel-body {
  padding: 24px !important;
}

.button-actions {
  .mat-icon {
    font-size: 15px !important;
  }
}

.mat-header-cell {
  mat-checkbox {
    color: white;

    ::deep .mat-checkbox-background {
      background-color: white;
    }

    ::deep &.mat-checkbox-focused {
      .mat-ink-ripple {
        background-color: rgba(255, 255, 255, 0.26);
      }
    }
  }
}

td.mat-column-actions {
  width: 20px;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $plusfrotalaranja;
}

.mat-horizontal-stepper-header {
  pointer-events: none;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: $plusfrotalaranja;
}

.mat-radio-button[color="plusfrota"].mat-radio-checked .mat-radio-outer-circle {
  border-color: $plusfrotalaranja;
}

.mat-radio-button[color="plusfrota"] .mat-radio-inner-circle,
.mat-radio-button[color="plusfrota"]
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button[color="plusfrota"].mat-radio-checked
  .mat-radio-persistent-ripple,
.mat-radio-button[color="plusfrota"]:active .mat-radio-persistent-ripple {
  background-color: $plusfrotalaranja;
}

.primary-snackbar {
  color: white;
  background: $primary;
}

.success-snackbar {
  color: white;
  background: $green;
}

.warn-snackbar {
  color: white;
  background: $red;
}

mat-form-field {
  width: 100%;
}

.filtersBlock .mat-form-field-wrapper,
.no-space-form-field-wrapper {
  margin-bottom: -1.25em;
}

.text-inside-form {
  height: 48px;
  line-height: 48px;
}

.mat-select-search-inside-mat-option .mat-select-search-clear {
  top: 0 !important;
  right: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

tr.table-cursor-pointer:hover {
  background: whitesmoke;
}

.map,
.bg-map {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(100vh - 70px);
}

.bg-plusfrota {
  background-color: $plusfrotalaranja;
}

.bg-map {
  background-color: rgba(5, 36, 51, 0.7);
  z-index: 97;
  margin-bottom: calc((100vh - 70px) * -1);
  display: flex;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding-right: 10px !important;
}

.image-input .altImg {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
}

.image-input .remImg {
  position: absolute;
  right: -10px;
  bottom: -5px;
}

.bg-dark-plus {
  background-color: #001d2a !important;
}
